import { Action, Dispatch } from 'redux';
import { fetchMonthOverMonth, MonthOverMonth, updateAnnualBudget } from 'data-layer/monthOverMonth';

export const SET_MONTH_OVER_MONTH = 'SET_MONTH_OVER_MONTH';

export interface SetMonthOverMonthAction extends Action<typeof SET_MONTH_OVER_MONTH> {
  monthOverMonth: MonthOverMonth;
}

export const setMonthOverMonth: (
  monthOverMonth: MonthOverMonth,
) => SetMonthOverMonthAction = monthOverMonth => ({
  type: SET_MONTH_OVER_MONTH,
  monthOverMonth,
});

export const getMonthOverMonth = (householdId: string) => async (dispatch: Dispatch) => {
  const monthOverMonth = await fetchMonthOverMonth(householdId);
  return dispatch(setMonthOverMonth(monthOverMonth));
};

export const saveAnnualBudget = (
  householdId: string,
  annualBudget: number,
  callback?: () => void,
) => async (dispatch: Dispatch) => {
  const monthOverMonth = await updateAnnualBudget(householdId, annualBudget);
  if (callback) {
    callback();
  }
  dispatch(setMonthOverMonth(monthOverMonth));
};
