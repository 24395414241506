import { path } from 'ramda';

import createApi, { parseResponse, withAuth } from './utils/createApi';
import { mergeWithLocalStorage } from './utils/localStorageUtils';
import { apiUrl } from './constants';
import { ApiResponse } from './types';

export type HouseholdProfile = {
  employmentStatus: string;
  firstName: string;
  isEmployed: boolean;
  lastName: string;
  profileId: string;
  province: string;
  // rrsps: Array<RRSP>;
};

export type Household = {
  city: string;
  country: string;
  fullAddress: string;
  id: string | number;
  name: string;
  postalCode: string;
  province: string;
  streetNumber: string;
  type: string;
  unitNumber: string;
};

export type HouseholdToPersonRelationship = {
  householdQID: string;
  memberQID: string;
  memberType: string;
};

const LOCAL_STORAGE_KEY = 'household';

export const householdApi = withAuth(createApi(`${apiUrl}/households`));

const parseData: <T>(input: { data: [T] }) => T | undefined = path(['data', 0]);

export const fetchHousehold = async (householdId: string | number): Promise<Household> => {
  const response = await householdApi(householdId);
  const data = await parseResponse<ApiResponse<Household>>(response);
  // @ts-ignore
  return mergeWithLocalStorage(LOCAL_STORAGE_KEY, parseData(data));
};

export const fetchHouseholdPersonIds = async (
  householdId: string,
): Promise<Array<string>> => {
  const response = await householdApi(`${householdId}/members`);
  const data = await parseResponse<ApiResponse<HouseholdToPersonRelationship>>(response);
  return data.data.map(({ memberQID }) => memberQID);
};
