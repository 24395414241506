export enum METHOD {
  DELETE,
  GET,
  OPTION,
  PATCH,
  POST,
  PUT,
}

export interface Fetch {
  (url: RequestInfo, options?: RequestInit): Promise<Response>;
}

export interface CallApi {
  (path?: string | number, options?: RequestInit): Promise<Response>;
}
// method?: keyof typeof METHOD
export interface CreateApi {
  (url: RequestInfo, fetchCall?: CallApi | Fetch): CallApi;
}

const normalizePath = (path?: string | number): string => {
  if (typeof path === 'string' && path.indexOf('/') === 0) {
    return path;
  }

  if (path) {
    return `/${path}`;
  }

  return '';
};

const {
  REACT_APP_QWEALTH_ENV,
  REACT_APP_QWEALTH_API_VERSION,
  REACT_APP_QWEALTH_API_KEY,
} = process.env;

let accessToken: string;
export const setAccessToken = (idToken: string) => {
  accessToken = idToken;
};

export const withAuth = (callApi: CallApi): CallApi => async (path, options) => {
  if (
    !REACT_APP_QWEALTH_ENV ||
    !REACT_APP_QWEALTH_API_VERSION ||
    !REACT_APP_QWEALTH_API_KEY ||
    !accessToken
  ) {
    throw new Error('Missing API Key');
  }

  console.log(`QWealth API cfg: 
      env ${REACT_APP_QWEALTH_ENV} 
      version ${REACT_APP_QWEALTH_API_VERSION}`);

  return callApi(path, {
    ...options,
    headers: {
      APIKey: REACT_APP_QWEALTH_API_KEY,
      Authorization: `Bearer ${accessToken}`,
      environment: REACT_APP_QWEALTH_ENV,
      version: REACT_APP_QWEALTH_API_VERSION,
      ...options?.headers,
    },
  });
};

export const withHttpMethod = (method: keyof typeof METHOD = 'GET') => (
  callApi: CallApi,
): CallApi => (path, options) => callApi(path, { ...options, method });

export const withData = (data: any) => (callApi: CallApi): CallApi => (path, options) =>
  callApi(path, {
    ...options,
    body: JSON.stringify(data),
    headers: {
      ...options?.headers,
      'Content-Type': 'application/json',
    },
  });

export const parseResponse = <T>(response: Response): Promise<T> => response.json();

const createApi: CreateApi = (url, fetchCall = fetch) => (path, options) =>
  fetchCall(`${url}${normalizePath(path)}`, options);

export default createApi;
