import { compose, find, map, prop, propEq, propOr, values } from 'ramda';

import { PersonWithRRSP } from 'data-layer/person';
import { PersonsReduxState } from 'redux/reducers/persons';
import { createSelector } from 'reselect';

export type State = {
  persons?: PersonsReduxState;
};

export const selectPersonsRoot: (s: State) => PersonsReduxState | {} = propOr({}, 'persons');

export const selectPersonIds = createSelector(
  selectPersonsRoot,
  compose<PersonsReduxState | {}, Array<PersonWithRRSP>, Array<string>>(map(prop('QID')), values),
);

export const selectPrimary = compose<
  State,
  PersonsReduxState | {},
  Array<PersonWithRRSP>,
  PersonWithRRSP | undefined
>(find<PersonWithRRSP>(propEq('memberType', 'Primary')), values, selectPersonsRoot);

export const selectSecondary = compose<
  State,
  PersonsReduxState | {},
  Array<PersonWithRRSP>,
  PersonWithRRSP | undefined
>(find<PersonWithRRSP>(propEq('memberType', 'Secondary')), values, selectPersonsRoot);
