import React from 'react';
import { useMsal } from '@azure/msal-react';

import { Box, Button, NumberedList, SplashPage, styled } from '@xspance/qcore';
import { scopes } from 'data-layer/msal';

const SplashPageContainer = styled.div`
  margin-top: 1.143rem;

  @media screen and (min-width: 960px) {
    margin-top: 5rem;
  }
`;

export default () => {
  const { instance } = useMsal();
  return (
    <SplashPageContainer>
      <SplashPage>
        <SplashPage.Body>
          <SplashPage.Title>QCash</SplashPage.Title>
          <Box mb="largest">
            <p>
              Having precision with your annual cash flow is the single most important component to
              insuring viability with your Financial Plan. Without accuracy of inflows and outflows,
              we can NEVER:
            </p>
          </Box>
          {/* @ts-ignore */}
          <NumberedList>
            <li>Have confidence in the Financial Plan we are modelling into the Future.</li>
            <li>
              Know what proactive adjustments can be made with limited disruption to your life.
            </li>
          </NumberedList>
          <p>
            Stated succinctly, an understanding of your personal Cash Flows provides clarity with
            the question:
          </p>
          <SplashPage.Quote>Can I live the life I want today?</SplashPage.Quote>
          <SplashPage.Actions>
            <Button
              onClick={() => {
                const [, householdId] = window.location.search.split('=');
                instance.loginRedirect({
                  redirectStartPage: `/${householdId}/income-summary`,
                  scopes,
                });
              }}
            >
              Let&apos;s Begin
            </Button>
          </SplashPage.Actions>
        </SplashPage.Body>
        <SplashPage.Image imageSrc="/assets/intro.jpg" />
      </SplashPage>
    </SplashPageContainer>
  );
};
