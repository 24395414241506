import {
  any,
  apply,
  compose,
  isNil,
  map,
  mapObjIndexed,
  multiply,
  not,
  pick,
  prop,
  propOr,
  reject,
  sum,
  values,
} from 'ramda';
import { CalcDecorator, when, hasAllValues, run, CalculateFn } from '../utils/function';

export const constant = (amount: number) => () => amount;

export const zero = constant(0);

/**
 * Ensures all input values before giving it to calc function.
 * If any input value has undefined or null, then returns undefined without
 * going through calc function.
 *
 * calc => input => (number | undefined)
 */
// @ts-ignore
export const returnUndefinedWhenMissingInput: CalcDecorator = calc => when(hasAllValues, calc);

interface EnsuredCallback<T> {
  (input: T): number;
}
const ensureValues = mapObjIndexed(v => (isNil(v) ? 0 : v));

export function ensureMissingInputsToZero<T extends {}>(
  calcCallback: (input: T) => number | undefined,
): (input: T) => number {
  return (input => calcCallback(ensureValues(input) as T)) as EnsuredCallback<T>;
}

/**
 * Runs given array of calculations in order until the first value is returned.
 * However, if not all the inputs are available, then returns undefined
 */
export function runCalculations<T>(calcDefinitions: Array<CalculateFn<T, number | undefined>>) {
  return returnUndefinedWhenMissingInput(run(calcDefinitions));
}

export const multiplyProps: <T extends {}>(...props: Array<keyof T>) => (input: T) => number = (
  ...props
) => compose<any, any, any, any>(apply(multiply), values, pick(props));

export const sumProp: <T extends {}>(
  propName: keyof T,
) => (input: Array<T>) => number | undefined = propName =>
  when(
    // @ts-ignore
    input => input?.length > 0 && any(compose(not, isNil, prop(propName)))(input),
    // @ts-ignore
    compose(sum, map<any, number>(propOr(0, propName))),
  );

export const sumValuesInObject: (
  input: Record<string, number | undefined>,
) => number | undefined = when(
  compose(any(compose(not, isNil)), values),
  compose(sum, compose<any, any, any>(reject(isNil), values)),
);
