import { Action, Dispatch } from 'redux';
import { fetchIncomeSummaryForHousehold, IncomeSummary } from '../../data-layer/incomeSummary';

export const SET_INCOME_SUMMARY_TYPE = 'SET_INCOME_SUMMARY';

export interface SetIncomeSourceAction extends Action<typeof SET_INCOME_SUMMARY_TYPE> {
  incomeSummary: IncomeSummary;
}

export const getIncomeSummary = (householdId: string) => async (dispatch: Dispatch) => {
  const incomeSummary = await fetchIncomeSummaryForHousehold(householdId);
  return dispatch<SetIncomeSourceAction>({
    incomeSummary,
    type: SET_INCOME_SUMMARY_TYPE,
  });
};
