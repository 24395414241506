import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AppPage, Header as CoreHeader, Tab } from '@xspance/qcore';

import { Household } from '../data-layer/household';
import useCurrentPathName from '../hooks/useCurrentPathName';
import { selectCurrentHousehold } from '../redux/selectors/householdSelectors';

const Header = () => {
  const { name } = useSelector(selectCurrentHousehold) as Household;
  const currentPath = useCurrentPathName();
  const { url } = useRouteMatch('/:householdId') || {};
  const { push } = useHistory();
  const selectHandler = useCallback(
    key => {
      if (key) {
        push(`${url}/${key}`);
      }
    },
    [push, url],
  );

  return (
    <>
      <AppPage.Header
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        bg="green"
      >
        <CoreHeader.Title>{name}</CoreHeader.Title>
      </AppPage.Header>
      <AppPage.Nav onSelect={selectHandler} selectedTab={currentPath}>
        <Tab id="income-summary">Income Summary</Tab>
        <Tab id="month-over-month">Month Over Month</Tab>
        <Tab id="path-funding">Path Funding</Tab>
      </AppPage.Nav>
    </>
  );
};

export default Header;
