import { indexBy, isNil, prop } from 'ramda';
import { Reducer } from 'redux';

import { MonthlyActual, MonthOverMonth } from 'data-layer/monthOverMonth';

import { SET_MONTH_OVER_MONTH, SetMonthOverMonthAction } from '../actions/monthOverMonthAction';
import reducerFactory from './reducerFactory';

export interface MonthOverMonthReduxState extends Omit<MonthOverMonth, 'monthlyActuals'> {
  monthlyActuals?: Record<string, MonthlyActual>;
}

const setMonthOverMonth: Reducer<MonthOverMonthReduxState, SetMonthOverMonthAction> = (
  state,
  { monthOverMonth },
) => {
  const { monthlyActuals, ...rest } = monthOverMonth;
  const newState: MonthOverMonthReduxState = Object.entries(rest).reduce(
    (acc, [key, value]) => {
      if (!isNil(value)) {
        acc[key] = value;
      }
      return acc;
    },
    { ...state },
  );

  if (monthlyActuals) {
    newState.monthlyActuals = indexBy(prop('guid'), monthlyActuals);
  }

  return newState;
};

export default reducerFactory(
  {
    [SET_MONTH_OVER_MONTH]: setMonthOverMonth,
  },
  {},
);
