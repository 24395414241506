import { compose } from 'ramda';
import { bffApiUrl, bffHouseholdApi } from './constants';
import createApi, { parseResponse, withAuth, withData, withHttpMethod } from './utils/createApi';

export interface Item {
  amount: number;
  name: string;
}

export interface IncomeSource extends Item {}

export interface DeductionAndAdjustment extends Item {}

export interface FederalTaxCredits {
  ageAmount?: number;
  eligibleDependent?: number;
  id?: string;
}

export type IncomeSummaryProfile = {
  deductionsAndAdjustments: Array<DeductionAndAdjustment>;
  federalTaxCredits: FederalTaxCredits;
  incomeSources: Array<IncomeSource>;
};

export type IncomeSummary = {
  deductionsAndAdjustments: Record<string, Array<DeductionAndAdjustment>>;
  federalTaxCredits: Record<string, FederalTaxCredits>;
  incomeSources: Record<string, Array<IncomeSource>>;
};

export type RRSP = {
  amount: 'Max' | number;
  type: string;
};

export type RRSPForHousehold = {
  rrspsByPerson: Record<string, Array<RRSP>>;
};

export const personBffApi = withAuth(createApi(`${bffApiUrl}person`));

export const fetchIncomeSummaryForHousehold = async (
  householdId: string,
): Promise<IncomeSummary> => {
  const response = await bffHouseholdApi(householdId)('incomeSummary');
  const data = await parseResponse<IncomeSummary>(response);
  return data;
};

export const fetchRRSPsForHousehold = async (householdId: string): Promise<RRSPForHousehold> => {
  const response = await bffHouseholdApi(householdId)('rrsp');
  const data = await parseResponse<RRSPForHousehold>(response);
  return data;
};

export const updateIncomeSources = async (
  householdId: string,
  profileId: string,
  incomeSources: Array<IncomeSource>,
): Promise<void> => {
  const api = compose(withHttpMethod('PUT'), withData(incomeSources))(bffHouseholdApi(householdId));
  await api(`person/${profileId}/incomeSource`);
};

export const updateDeductionsAndAdjustments = async (
  profileId: string,
  deductionsAndAdjustments: Array<DeductionAndAdjustment>,
): Promise<void> => {
  const api = compose(withHttpMethod('PUT'), withData(deductionsAndAdjustments))(personBffApi);
  await api(`${profileId}/deduction`);
};

export const updateFederalTaxCredits = async (
  profileId: string,
  federalTaxCredits: FederalTaxCredits,
): Promise<void> => {
  const api = compose(withHttpMethod('PUT'), withData(federalTaxCredits))(personBffApi);
  await api(`${profileId}/federalTaxCredit`);
};
