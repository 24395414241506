import { Action, Dispatch } from 'redux';

import { fetchHouseholdPersonIds } from 'data-layer/household';
import { fetchPersons, PersonWithRRSP } from 'data-layer/person';
import { fetchRRSPsForHousehold } from 'data-layer/incomeSummary';

export const SET_PERSONS_TYPE = 'SET_PERSONS';

export interface SetPersonsAction extends Action<typeof SET_PERSONS_TYPE> {
  persons: Array<PersonWithRRSP>;
}

export const getPersons = (householdId: string) => async (dispatch: Dispatch) => {
  const personIds = await fetchHouseholdPersonIds(householdId);
  const [persons, { rrspsByPerson: rrsps }] = await Promise.all([
    fetchPersons(householdId, personIds),
    fetchRRSPsForHousehold(householdId),
  ]);

  const mergedPersons = persons.map(person => ({
    ...person,
    rrsps: rrsps[person.QID],
  }));

  // dispatch set persons
  dispatch<SetPersonsAction>({
    persons: mergedPersons,
    type: SET_PERSONS_TYPE,
  });
};
