import { compose } from 'ramda';
import { bffHouseholdApi } from './constants';
import { parseResponse, withData, withHttpMethod } from './utils/createApi';

export type MonthlyActual = {
  date: string;
  guid: string;
  value: number;
};

export type Goal = {
  id: string;
  accountId?: string;
  actual?: number;
  comment?: string;
  target?: number;
};

export type ItemizedGoals = {
  id: string;
  goals: Array<Goal>;
};

export type SpendingGoals = {};

export type MonthOverMonth = {
  actualSpent?: number;
  annualBudget?: number;
  monthlyActuals?: Array<MonthlyActual>;
  savings?: ItemizedGoals;
  spendings?: ItemizedGoals;
};

export const fetchMonthOverMonth = async (
  householdId: string | number,
): Promise<MonthOverMonth> => {
  const response = await bffHouseholdApi(householdId)('monthOverMonth');
  const data = await parseResponse<MonthOverMonth>(response);
  return data;
};

export const updateAnnualBudget = async (
  householdId: string,
  annualBudget: number,
): Promise<MonthOverMonth> => {
  const api = compose(withHttpMethod('PUT'), withData(annualBudget))(bffHouseholdApi(householdId));
  const response = await api('annualBudget');
  const updatedMonthOverMonth = await parseResponse<MonthOverMonth>(response);
  return updatedMonthOverMonth;
};

export const updateSavings = async (
  householdId: string,
  savings: ItemizedGoals,
): Promise<MonthOverMonth> => {
  const api = compose(withHttpMethod('PUT'), withData(savings), bffHouseholdApi)(householdId);
  const response = await api('savingsGoals');
  const updatedMonthOverMonth = await parseResponse<MonthOverMonth>(response);
  return updatedMonthOverMonth;
};

export const updateSpendings = async (
  householdId: string,
  spendings: ItemizedGoals,
): Promise<MonthOverMonth> => {
  const api = compose(withHttpMethod('PUT'), withData(spendings), bffHouseholdApi)(householdId);
  const response = await api('spendingsGoals');
  const updatedMonthOverMonth = await parseResponse<MonthOverMonth>(response);
  return updatedMonthOverMonth;
};
