import { Reducer } from 'redux';

import { Household } from '../../data-layer/household';
import { SET_HOUSEHOLD_TYPE, SetHouseholdAction } from '../actions/householdAction';
import reducerFactory from './reducerFactory';

export interface HouseholdReduxState extends Household {}

const setHousehold: Reducer<HouseholdReduxState, SetHouseholdAction> = (state, { household }) => ({
  ...state,
  ...household,
});

export default reducerFactory({
  [SET_HOUSEHOLD_TYPE]: setHousehold,
});
