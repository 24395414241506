import useHouseholdIdPath from 'hooks/useHouseholdIdPath';
import React from 'react';
import { TopBar as QCoreTopBar } from '@xspance/qcore';
import { qcentralUrl } from '../data-layer/constants';

const TopBar = () => {
  const householdId = useHouseholdIdPath();
  const href = `${qcentralUrl}/household?household=${householdId}`;
  return (
    <QCoreTopBar title="QCash" imageSrc="/assets/QCash_logo.png" backToQCentralHref={href} />
  );
};

export default TopBar;
