import { styled } from '@xspance/qcore';

import { StyledComponent } from 'theme/types';
import { Box, BoxProps } from './Box';
import withDefaults from './withDefaults';

export interface TextInputProps extends BoxProps {
  type?: string;
}

// @ts-ignore
const StyledTextInput = styled(Box)``;

export const TextInput = withDefaults<TextInputProps, HTMLInputElement>({
  as: 'input',
  color: 'primary',
  size: 'default',
  type: 'text',
})(StyledTextInput as StyledComponent<TextInputProps, HTMLInputElement>);
