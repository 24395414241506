import React, { lazy } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import NotFoundPage from './components/NotFoundPage';

const IncomeSummary = lazy(() => import('./income-summary'));
const MonthOverMonth = lazy(() => import('./month-over-month'));
const GoalFunding = lazy(() => import('./goal-funding'));

export default () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={url} to="/" />
      <Route component={IncomeSummary} path={`${url}/income-summary`} />
      <Route component={MonthOverMonth} path={`${url}/month-over-month`} />
      <Route component={GoalFunding} path={`${url}/path-funding`} />
      <Route component={NotFoundPage} path="*" />
    </Switch>
  );
};
