import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { defaultTheme, ThemeProvider } from '@xspance/qcore';
import { MsalProvider } from '@azure/msal-react';

import { msalInstance } from 'data-layer/msal';

import App from './App';
import store from './redux/store';

import './theme.scss';
import './App.scss';
import './Autosuggest.scss';

const { REACT_APP_VERSION } = process.env;

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: defaultTheme.colors.green,
    primaryTransparent: defaultTheme.colors.greenTransparent,
  },
};

// eslint-disable-next-line no-console
console.log(`Current app version is: ${REACT_APP_VERSION}`);

render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <Router>
          <Provider store={store}>
            <App />
          </Provider>
        </Router>
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
