import { propEq } from 'ramda';
import { Reducer } from 'redux';

import { PersonWithRRSP } from 'data-layer/person';
import { SetPersonsAction, SET_PERSONS_TYPE } from 'redux/actions/personsAction';

import reducerFactory from './reducerFactory';

export interface PersonsReduxState extends Record<string, PersonWithRRSP> {}

const setPersons: Reducer<PersonsReduxState, SetPersonsAction> = (state = {}, { persons }) => {
  if (persons.length === 0) {
    return state;
  }

  const primary = persons.find(propEq('memberType', 'Primary')) as PersonWithRRSP;
  const secondary = persons.find(propEq('memberType', 'Secondary'));
  const newState = {
    ...state,
    [primary.QID]: primary,
  };

  if (secondary) {
    newState[secondary.QID] = secondary;
  }

  return newState;
};

export default reducerFactory({
  [SET_PERSONS_TYPE]: setPersons,
});
