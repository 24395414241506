import { compose, find, isNil, not, path, prop, propEq, propOr, reject } from 'ramda';
import createApi, { parseResponse, withAuth } from './utils/createApi';
import { apiUrl } from './constants';
import { ApiResponse } from './types';
import { RRSP } from './incomeSummary';
import { HouseholdToPersonRelationship } from './household';

export type PersonIncome = {
  employerName?: string;
  employmentBonus?: number;
  employmentIncome?: number;
  employmentStatus: string;
  otherIncome?: number;
};

export type PersonAddress = {
  city: string;
  country: string;
  isCurrentAddress: boolean;
  postal?: string;
  provinceOrState: string;
  streetName: string;
  streetNumber: number;
  unitNumber?: string;
};

export type Person = PersonIncome &
  PersonAddress & {
    dateOfBirth: string;
    firstName: string;
    householdMembers: Array<HouseholdToPersonRelationship>;
    lastName: string;
    memberType: string;
    QID: string;
    values?: any;
  };

export type PersonWithRRSP = Person & {
  rrsps?: Array<RRSP>;
};

export const personApi = withAuth(createApi(`${apiUrl}/persons`));

const isValidPerson = compose<Person, string, boolean, boolean>(
  not,
  isNil,
  prop<string, any>('firstName'),
);

const parseData: <T>(response: ApiResponse<T>) => T | undefined = path(['data', 0]);

export const fetchCurrentAddress = async (personId: string): Promise<PersonAddress | undefined> => {
  const addressApi = createApi(`${personId}/addresses`, personApi);
  const response = await addressApi();
  const parsedResponse = await parseResponse<ApiResponse<PersonAddress>>(response);
  const currentAddress = compose(
    find<PersonAddress>(propEq('isCurrentAddress', true)),
    propOr([] as PersonAddress[], 'data'),
  )(parsedResponse);

  // if there's no current address, then just return the first data in the addresses
  return currentAddress || parseData(parsedResponse);
};

export const fetchPersonIncome = async (personId: string): Promise<PersonIncome | undefined> => {
  const incomeApi = createApi(`${personId}/income`, personApi);
  const response = await incomeApi();
  const parsedResponse = await parseResponse<ApiResponse<PersonIncome>>(response);
  return parseData(parsedResponse);
};

export const fetchPerson = async (
  householdId: string,
  personId: string,
): Promise<Person | undefined> => {
  const [personResponse, personAddress, personIncome] = await Promise.all([
    personApi(personId),
    fetchCurrentAddress(personId),
    fetchPersonIncome(personId),
  ]);

  const personData = await parseResponse<ApiResponse<Person>>(personResponse).then(parseData);
  if (!personData || !isValidPerson(personData)) {
    return undefined;
  }

  const address = personAddress || (await fetchCurrentAddress(householdId));

  const person = {
    ...address,
    ...personAddress,
    ...personData,
    ...personIncome,
  };

  const foundRelationship = person.householdMembers.find(r => r.householdQID === householdId);
  if (!person.memberType && foundRelationship) {
    return { ...person, memberType: foundRelationship.memberType };
  }
  return person;
};

export const fetchPersons = (
  householdId: string,
  personIds: Array<string | number>,
): Promise<Array<Person>> =>
  // @ts-ignore
  Promise.all(personIds.map(personId => fetchPerson(householdId, personId))).then(reject(isNil));
