import { combineReducers } from 'redux';
import dataSource from './dataSource';
import household from './household';
import incomeSummary from './incomeSummary';
import monthOverMonth from './monthOverMonth';
import persons from './persons';

export default combineReducers({
  dataSource,
  household,
  incomeSummary,
  monthOverMonth,
  persons,
});
