import { createMsalInstance } from '@xspance/qcore';

const {
  REACT_APP_QWEALTH_MICROSOFT_APPLICATION_ID,
  REACT_APP_QWEALTH_MICROSOFT_AUTHORITY,
} = process.env;

export const scopes = [
  'calendars.readwrite',
  'directory.read.all',
  'files.read.all',
  'files.readwrite.all',
  'group.read.all',
  'mailboxSettings.read',
  'orgcontact.read.all',
  'people.read',
  'sites.readwrite.all',
  'tasks.readwrite',
  'user.read.all',
  'user.read',
];

export const msalInstance = createMsalInstance(
  {
    auth: {
      authority: REACT_APP_QWEALTH_MICROSOFT_AUTHORITY,
      clientId: REACT_APP_QWEALTH_MICROSOFT_APPLICATION_ID || '',
    },
  },
  { scopes },
);
