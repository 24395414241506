import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AppLayout, AppPage, Loader, useAcquireToken } from '@xspance/qcore';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import { getHousehold } from 'redux/actions/householdAction';
import { getPersons } from 'redux/actions/personsAction';

import Intro from 'components/Intro';
import { setAccessToken } from 'data-layer/utils/createApi';

import useDispatchOnMount from './hooks/useDispatchOnMount';
import useHouseholdIdPath from './hooks/useHouseholdIdPath';
import { getDataSource } from './redux/actions/dataSourceAction';

import AppMain from './AppMain';
import Breadcrumbs from './components/Breadcrumbs';
import Header from './components/Header';
import NotFoundPage from './components/NotFoundPage';
import TopBar from './components/TopBar';

const App = () => {
  const { idToken, isInProgress } = useAcquireToken();
  useEffect(() => {
    if (idToken) {
      setAccessToken(idToken);
    }
  }, [idToken]);

  // fetch global data source
  useDispatchOnMount(getDataSource, idToken);

  const householdId = useHouseholdIdPath();
  useDispatchOnMount(getHousehold, idToken ? householdId : undefined);
  useDispatchOnMount(getPersons, idToken ? householdId : undefined);

  const isIntro = useRouteMatch({ path: '/', exact: true });

  if (isInProgress) {
    return (
      <AppLayout alignItems="center">
        <Loader />
      </AppLayout>
    );
  }

  return (
    <>
      {!isIntro && <TopBar />}
      <AppPage style={{ maxWidth: '1180px' }}>
        <AuthenticatedTemplate>
          {!isIntro && (
            <>
              <AppPage.Breadcrumbs>
                <Breadcrumbs />
              </AppPage.Breadcrumbs>
              <Header />
            </>
          )}
          {idToken && (
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route component={Intro} path="/" exact />
                <Route component={AppMain} path="/:household" />
                <Route component={NotFoundPage} path="*" />
              </Switch>
            </Suspense>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Intro />
        </UnauthenticatedTemplate>
      </AppPage>
    </>
  );
};

export default App;
