import { Reducer } from 'redux';

import {
  DeductionAndAdjustment,
  IncomeSource,
  // IncomeSummaryProfile,
  FederalTaxCredits,
} from '../../data-layer/incomeSummary';
import { SET_INCOME_SUMMARY_TYPE, SetIncomeSourceAction } from '../actions/incomeSummaryAction';
import reducerFactory from './reducerFactory';

export interface IncomeSummaryReduxState {
  deductionsAndAdjustments: Record<string, Array<DeductionAndAdjustment>>;
  federalTaxCredits: Record<string, FederalTaxCredits>;
  incomeSources: Record<string, Array<IncomeSource>>;
}

// interface ReduceFn {
//   <T>(listName: keyof IncomeSummaryProfile): (
//     acc: Record<string, T>,
//     profile: IncomeSummaryProfile,
//   ) => Record<string, T>;
// }

// const reduceFn: ReduceFn = listName => (acc, profile) => {
//   // @ts-ignore
//   acc[profile.profileId] = profile[listName];
//   return acc;
// };

const setIncomeSummary: Reducer<IncomeSummaryReduxState, SetIncomeSourceAction> = (
  state,
  { incomeSummary },
) => ({
  ...state,
  ...incomeSummary,
});
// ) => {
//   // normalize incomeSources
//   const incomeSources = profiles.reduce(reduceFn<Array<IncomeSource>>('incomeSources'), {});
//   const deductionsAndAdjustments = profiles.reduce(
//     reduceFn<Array<DeductionAndAdjustment>>('deductionsAndAdjustments'),
//     {},
//   );
//   const federalTaxCredits = profiles.reduce(reduceFn<FederalTaxCredits>('federalTaxCredits'), {});

//   return {
//     ...state,
//     deductionsAndAdjustments,
//     federalTaxCredits,
//     incomeSources,
//   };
// };

export default reducerFactory({
  [SET_INCOME_SUMMARY_TYPE]: setIncomeSummary,
});
